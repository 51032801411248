<template>
  <b-modal
    :id="`modal-meter-log-${id}`"
    ref="refModal"
    :title="t('Ghi chỉ số')"
    size="lg"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    scrollable
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row v-if="!itemLocal.disableFilter">
          <b-col

            md="4"
          >
            <select-apartment
              v-model="itemLocal.apartment"
              :filter-active="true"
              :default-value="itemLocal.apartment"
              :label="t('Tòa nhà')"
              :enable-default-select="true"
            />
          </b-col>
          <b-col
            md="4"
          >
            <select-room
              v-model="itemLocal.room"
              :filter-active="true"
              :default-value="itemLocal.room"
              :label="t('Phòng')"
              :apartment="itemLocal.apartment"
            />
          </b-col>
          <b-col
            md="4"
          >
            <select-meter-type
              v-model="itemLocal.meterType"
              :default-value="itemLocal.meterType"
              :label="t('Loại công tơ')"
            />
          </b-col>

        </b-row>
        <b-row v-if="!itemLocal.disableFilter">

          <b-col :md="(itemLocal.id && itemLocal.id > 0) ? 12 : 6">
            <select-month
              v-model="itemLocal.month"
              required
              :default-value="itemLocal.month"
              :label="t('Tháng chốt')"
            />
          </b-col>
          <b-col cols="6">
            <select-date
              v-model="itemLocal.issueDate"
              required
              :label="t('Ngày chốt')"
              :default-value="itemLocal.issueDate"
            />
          </b-col>
        </b-row>
        <b-row v-if="itemLocal.disableFilter">
          <b-col>
            <select-month
              v-model="itemLocal.month"
              required
              :default-value="itemLocal.month"
              :label="t('Tháng chốt')"
            />
          </b-col>
        </b-row>
        <b-row v-if="!itemLocal.disableFilter">
          <b-col
            cols="12"
            class="mt-2"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="itemLocal.noRecordOnly"
                name="check-button"
                switch
                inline
              >
                {{ t('Chỉ hiện công tơ chưa chốt trong tháng') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <meter-table
              v-model="itemLocal.meters"
              :data="itemLocal.meters"
              :issue-date="itemLocal.issueDate"
              :apartment="itemLocal.apartment"
              :room="itemLocal.room"
              :month="itemLocal.month"
              :type="itemLocal.meterType"
              :no-record-only="itemLocal.noRecordOnly"
            />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormCheckbox,
} from 'bootstrap-vue';
import { ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectRoom from '@/views/components/SelectRoom.vue';
import SelectMeterType from '@/views/components/SelectMeterType.vue';
import SelectDate from '@/views/components/SelectDate.vue';
import SelectMonth from '@/views/components/SelectMonth.vue';
import useMeterLogModal from './useMeterLogModal';
import MeterTable from './MeterTable.vue';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BForm,
    ValidationObserver,
    BFormGroup,
    BFormCheckbox,
    SelectApartment,
    SelectRoom,
    SelectMeterType,
    SelectDate,
    MeterTable,
    SelectMonth,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
      default: 0,
    },
    apartment: {
      type: Object,
      default: null,
    },
    room: {
      type: Object,
      default: null,
    },
    approve: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
    };
  },
  created() {
    if (this.item) {
      this.fetchMeters();
    }
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      fetchMeters,
      t,
      onSubmit,
    } = useMeterLogModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      fetchMeters,
      t,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
