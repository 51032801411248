<template>
  <b-form-group
    :label="label"
    :state="state"
  >
    <template
      v-if="label"
      v-slot:label
    >
      {{ label }}
      <span
        v-if="required"
        class="text-danger"
      >
        (*)
      </span>
    </template>
    <v-select
      id="contract"
      v-model="contract"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="name"
      :options="contracts"
      :disabled="room == null || !enable"
      :placeholder="t('Hợp đồng')"
      @input="valueChanged"
      @open="onOpen"
    ><!-- eslint-disable-next-line vue/no-unused-vars  -->
      <template #no-options="{ search, searching, loading }">
        {{ t('Không có dữ liệu') }}
      </template></v-select>
    <small class="text-danger">{{ error }}</small>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import useJwt from '@/auth/jwt/useJwt';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    apartment: {
      type: Object,
      default: null,
    },
    room: {
      type: Object,
      default: null,
    },
    bed: {
      type: Object,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    enable: {
      type: Boolean,
      default: true,
    },
    autoSelect: {
      type: Boolean,
      default: true,
    },
    ignoreLiquid: {
      type: Boolean,
      default: true,
    },
    countInvoice: {
      type: Boolean,
      default: false,
    },
    includeRelation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contract: this.defaultValue,
      contracts: [],
    };
  },
  watch: {

    apartment() {
      this.contract = null;
      this.valueChanged();
      this.fetchContracts();
    },
    room() {
      this.contract = null;
      this.valueChanged();
      this.fetchContracts();
    },
    bed(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.contract = null;
      }

      this.valueChanged();
      this.fetchContracts();
    },
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
  methods: {
    valueChanged() {
      this.$emit('input', this.contract);
    },
    onOpen() {
      if (this.contracts.length === 0) {
        this.fetchContracts();
      }
    },
    fetchContracts() {
      const filter = {};

      if (this.apartment && this.apartment.id > 0) {
        filter.apartmentId = this.apartment.id;
      }
      if (this.room && this.room.id > 0) {
        filter.roomId = this.room.id;
      }
      if (this.bed && this.bed.id > 0) {
        filter.bedId = this.bed.id;
      }
      if (this.ignoreLiquid === true) {
        filter.status = 1;
      }
      if (this.countInvoice === true) {
        filter.countInvoice = true;
      }
      if (this.includeRelation) {
        useJwt.getContracts({ filter }).then(res => {
          this.contracts = res.data.items;
          if (!this.defaultValue && this.contracts && this.contracts.length === 1 && this.autoSelect && this.room) {
          // eslint-disable-next-line prefer-destructuring
            this.contract = this.contracts[0];
            this.valueChanged();
          }
        });
      } else {
        useJwt.selectContracts({ filter }).then(res => {
          this.contracts = res.data.items;
          if (!this.defaultValue && this.contracts && this.contracts.length === 1 && this.autoSelect && this.room) {
          // eslint-disable-next-line prefer-destructuring
            this.contract = this.contracts[0];
            this.valueChanged();
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
</style>
