<template>
  <b-modal
    id="modal-invoice-payment"
    ref="refModal"
    :title="t('Thanh toán hóa đơn')"
    size="lg"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    scrollable
    @show="openModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      v-if="item"
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col>
            <invoice-table :data="item.invoices" />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <span
              v-if="item.totalRemain > 0"
              class="text-danger"
            >{{ t('Bạn cần thu của cư dân số tiền là') }}: <strong>{{ Number(item.totalRemain).toLocaleString() }}</strong></span>
            <span
              v-if="item.totalRemain < 0"
              class="text-danger"
            >{{ t('Bạn cần thanh toán lại cho cư dân số tiền là') }}: <strong>{{ Number(Math.abs(item.totalRemain)).toLocaleString() }}</strong></span>
          </b-col>
        </b-row>
        <hr>
        <b-alert
          show
          variant="warning"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="StarIcon"
            />
            <span class="ml-25">{{ t('Trong trường hợp khách thanh toán thừa, Khoản tiền thừa sẽ được tự động gạch nợ ở các hóa đơn khác chưa thanh toán hết (nếu có) hoặc sẽ ghi nhận khoản tiền thừa vào mục "Tiền thừa" để trừ vào các hóa đơn sau này.') }}</span>
          </div>
        </b-alert>
        <b-row class="mt-2">

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Số tiền"
              rules="required"
            >
              <number-input
                v-model="itemLocal.amount"
                required
                :default-value="itemLocal.amount"
                :label="itemLocal.totalRemain > 0 ? t(`Số tiền thu`) : t(`Số tiền chi`)"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              :name="isTingTong ? t('Hình thức thanh toán') : t('Sổ quỹ')"
              rules="required"
            >
              <select-cashbook
                v-model="itemLocal.cashbook"
                required
                :enable-add="true"
                :default-value="itemLocal.cashbook"
                :label="isTingTong ? t('Hình thức thanh toán') : t('Sổ quỹ')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
                :apartment-id="itemLocal.apartment ? itemLocal.apartment.id : null"
              />
            </validation-provider>
          </b-col>

        </b-row>

        <b-row>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              :name="itemLocal.totalRemain > 0 ? `Ngày thực thu` : `Ngày thực chi`"
              rules="required"
            >
              <select-date
                v-model="itemLocal.issueDate"
                required
                :default-value="itemLocal.issueDate"
                :label="itemLocal.totalRemain > 0 ? t(`Ngày thực thu`) : t(`Ngày thực chi`)"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>

          <b-col
            v-if="itemLocal.totalRemain < 0"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Tên người nhận"
              rules="required"
            >
              <b-form-group
                :label="t('Tên người nhận')"
              >
                <template
                  v-slot:label
                >
                  {{ t('Tên người nhận') }}
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  id="receiver"
                  v-model="itemLocal.receiver"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="itemLocal.totalRemain > 0"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Tên người nộp"
              rules="required"
            >
              <b-form-group
                :label="t('Tên người nộp')"
              >
                <template
                  v-slot:label
                >
                  {{ t('Tên người nộp') }}
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  id="payer"
                  v-model="itemLocal.payer"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              :label="t('Ghi chú')"
            >
              <b-form-textarea
                v-model="itemLocal.note"
                rows="3"
              />
            </b-form-group>

          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                {{ t('Đính kèm') }}
              </div>
            </div>
          </b-col>
          <b-col cols="12">

            <media-view
              v-model="itemLocal.attachments"
              :label="null"
              :initial-value="itemLocal.attachments"
              :upload-imediately="true"
              @on-delete-uploaded-file="onDeleteAttachment"
            />

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BAlert,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectDate from '@/views/components/SelectDate.vue';
import SelectCashbook from '@/views/components/SelectCashbook.vue';
import NumberInput from '@/views/components/NumberInput.vue';
import MediaView from '@/views/components/media/MediaView.vue';
import InvoiceTable from './InvoiceTable.vue';
import useInvoicePaymentModal from './useInvoicePaymentModel';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BAlert,
    InvoiceTable,
    MediaView,
    SelectDate,
    SelectCashbook,
    NumberInput,

  },
  props: {
    item: {
      type: [Object, null],
      required: true,
    },
  },
  data() {
    return {
      required,

    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      openModal,
      onSubmit,
      t,
      onDeleteAttachment,
    } = useInvoicePaymentModal(props, emit, refFormObserver);

    const isTingTong = process.env.VUE_APP_PROVIDER === 'tingtong';

    return {
      isTingTong,
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      openModal,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      t,
      onDeleteAttachment,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

</style>
