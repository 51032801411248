<template>
  <b-modal
    id="modal-invoice"
    ref="refModal"
    :title="t('Hóa đơn')"
    :size="`${(itemLocal.id && itemLocal.isLiquidInvoice) || (itemLocal.id && itemLocal.id > 0 && itemLocal.approve) ? 'lg' : 'xl'}`"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    scrollable
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      v-if="!isLoading"
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <div v-if="itemLocal.approve && itemLocal.id && itemLocal.id > 0">
          <b-alert
            show
            variant="warning"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="AlertTriangleIcon"
              />
              <span class="ml-25">{{ t('Hóa đơn đã duyệt nên chỉ có thể sửa các thông tin về kỳ thanh toán, hạn thanh toán, ngày thanh toán.') }}<br><br><span v-if="itemLocal.apartment"> {{ t('Tòa nhà') }}: {{ itemLocal.apartment.name }}</span><br><span v-if="itemLocal.room">{{ t('Phòng') }}: {{ itemLocal.room.name }}</span><span v-if="itemLocal.bed"><br>{{ t('Giường') }}: {{ itemLocal.bed.name }}</span><br><span v-if="itemLocal.contract">{{ t('Hợp đồng') }}: {{ itemLocal.contract.name }}</span></span>
            </div>
          </b-alert>
          <b-row />
          <b-row>
            <b-col md="4">
              <select-month
                v-model="itemLocal.month"
                required
                :default-value="itemLocal.month"
                :label="t('Kỳ thanh toán')"
              />
            </b-col>

            <b-col md="4">
              <validation-provider
                #default="validationContext"
                name="Ngày lập"
                rules="required"
              >
                <select-date
                  v-model="itemLocal.issueDate"
                  required
                  :default-value="itemLocal.issueDate"
                  :label="t('Ngày lập')"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col md="4">
              <validation-provider
                #default="validationContext"
                name="Hạn thanh toán"
                rules="required"
              >
                <select-date
                  v-model="itemLocal.dueDate"
                  required
                  :default-value="itemLocal.dueDate"
                  :label="t('Hạn thanh toán')"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group :label="t('Ghi chú')">
                <b-form-textarea
                  id="note"
                  v-model="itemLocal.note"
                  placeholder=""
                  rows="3"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <b-row>
            <b-col
              cols="12"
              class="my-1"
            >
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-primary text-uppercase">
                1. {{ t('Thông tin chung') }}
              </div>
            </b-col>
          </b-row>

          <b-row>
            <!-- <b-col cols="3">
            <b-form-group
              label="Tên hóa đơn (tự động)"
            >
              <b-form-input
                v-model="invoiceName"
                readonly
              />
            </b-form-group>
          </b-col> -->

            <b-col :md="resolveColWidthIfDisableBed()">
              <validation-provider
                #default="validationContext"
                name="apartment"
                rules="required"
              >
                <select-apartment
                  v-model="itemLocal.apartment"
                  :filter-active="true"
                  required
                  :enable="!(itemLocal.id && itemLocal.id > 0)"
                  :default-value="itemLocal.apartment"
                  :label="t('Tòa nhà')"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col :md="resolveColWidthIfDisableBed()">
              <validation-provider
                #default="validationContext"
                name="room"
                rules="required"
              >
                <select-room
                  v-model="itemLocal.room"
                  :filter-active="true"
                  required
                  :enable="!(itemLocal.id && itemLocal.id > 0)"
                  :apartment="itemLocal.apartment"
                  :default-value="itemLocal.room"
                  :label="t('Phòng')"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                  :show-status="true"
                />
              </validation-provider>
            </b-col>
            <b-col
              v-if="resolveColWidthIfDisableBed(true) > 0"
              :md="resolveColWidthIfDisableBed()"
            >
              <validation-provider
                #default="validationContext"
                name="bed"
                :rules="itemLocal.room && itemLocal.room.numberActiveBeds > 0 ? 'required' : ''"
              >
                <select-bed
                  v-model="itemLocal.bed"
                  :filter-active="true"
                  :enable="!(itemLocal.id && itemLocal.id > 0) && itemLocal.room && itemLocal.room.numberActiveBeds > 0"
                  :room="itemLocal.room"
                  :default-value="itemLocal.bed"
                  :label="t('Giường')"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col :md="resolveColWidthIfDisableBed()">
              <validation-provider
                #default="validationContext"
                name="contract"
                rules="required"
              >
                <select-contract
                  v-model="itemLocal.contract"
                  :include-relation="true"
                  required
                  :enable="!(itemLocal.id && itemLocal.id > 0)"
                  :default-value="itemLocal.contract"
                  :apartment="itemLocal.apartment"
                  :room="itemLocal.room"
                  :bed="itemLocal.bed"
                  :count-invoice="true"
                  :label="t('Hợp đồng')"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                  :auto-select="true"
                />
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>

            <b-col cols="4">
              <select-month
                v-model="itemLocal.month"
                required
                :default-value="itemLocal.month"
                :label="t('Kỳ thanh toán')"
              />
            </b-col>
            <b-col
              v-if="isTingTong && itemLocal.typeObject && itemLocal.typeObject.id === '7'"
              md="4"
            >
              <b-form-group label="Ngày lập">
                <b-form-input
                  :value="parseDateToString(itemLocal.issueDate)"
                  disabled
                />
              </b-form-group>

            </b-col>
            <b-col
              v-else
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="Ngày lập"
                rules="required"
              >
                <select-date
                  v-model="itemLocal.issueDate"
                  required
                  :default-value="itemLocal.issueDate"
                  :label="t('Ngày lập')"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col md="4">
              <validation-provider
                #default="validationContext"
                name="Hạn thanh toán"
                rules="required"
              >
                <select-date
                  v-model="itemLocal.dueDate"
                  required
                  :default-value="itemLocal.dueDate"
                  :label="t('Hạn thanh toán')"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>

          </b-row>
          <b-row v-if="isTingTong">
            <b-col>
              <validation-provider
                #default="validationContext"
                name="Loại hóa đơn"
                rules="required"
              >
                <select-invoice-type
                  v-model="itemLocal.typeObject"
                  :label="t('Loại hóa đơn')"
                  :default-value="itemLocal.typeObject"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col v-if="itemLocal.typeObject.id === '7'">
              <validation-provider
                #default="validationContext"
                name="Ngày chốt chuyển/thanh lý/nhượng"
                rules="required"
              >
                <select-date
                  v-model="itemLocal.finalDate"
                  required
                  :default-value="itemLocal.finalDate"
                  :label="t('Ngày chốt chuyển/thanh lý/nhượng')"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
          </b-row>

          <hr class="mt-4">
          <b-row>
            <b-col
              cols="12"
              class="my-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                  2. {{ t('Dịch vụ & Phí') }}
                </div>
                <b-button
                  v-if="itemLocal.contract && !(itemLocal.id && itemLocal.isLiquidInvoice)"
                  v-b-modal.modal-list-service
                  variant="primary"
                  class="btn-icon ml-auto"
                  size="sm"
                >
                  <feather-icon icon="PlusSquareIcon" />
                </b-button>
              </div>
            </b-col>
            <b-col
              v-if="itemLocal.id && itemLocal.isLiquidInvoice"
              cols="4"
            >
              <validation-provider
                #default="validationContext"
                name="Trả lại cọc"
                rules=""
              >
                <number-input
                  v-model="itemLocal.refundDeposit"
                  :label="t('Trả lại tiền cọc')"
                  :default-value="itemLocal.refundDeposit"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />

              </validation-provider>
            </b-col>
            <b-col
              v-if="itemLocal.id && itemLocal.isLiquidInvoice"
              cols="4"
            >
              <validation-provider
                #default="validationContext"
                name="Trả lại tiền thừa"
                rules=""
              >
                <number-input
                  v-model="itemLocal.refundPrepaid"
                  :label="t('Trả lại tiền thừa')"
                  :default-value="itemLocal.refundPrepaid"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />

              </validation-provider>
            </b-col>
            <b-col
              v-if="itemLocal.id && itemLocal.isLiquidInvoice"
              cols="4"
            >
              <validation-provider
                #default="validationContext"
                name="Tiền phạt"
                rules=""
              >
                <number-input
                  v-model="itemLocal.penaltyFee"
                  :label="t('Tiền phạt')"
                  :default-value="itemLocal.penaltyFee"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />

              </validation-provider>
            </b-col>
            <b-col
              v-if="!(itemLocal.id && itemLocal.isLiquidInvoice)"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="Dịch vụ"
                rules="required"
              >
                <fee-table
                  v-model="itemLocal.items"
                  :invoice-id="itemLocal.id"
                  :rows="itemLocal.items"
                  :apartment="itemLocal.apartment"
                  :room="itemLocal.room"
                  :contract="itemLocal.contract"
                  :type="itemLocal.typeObject"
                  :error="validationContext.errors[0]"
                  :calculate-total-for-invoice-item="calculateTotalForInvoiceItem"
                  @calculate-quantity="reCalculateQuantityAndTotal"
                />

              </validation-provider>

            </b-col>
          </b-row>

          <b-row v-if="itemLocal.items && itemLocal.items.length > 0">
            <b-col cols="6" />
            <b-col md="6">
              <dl class="row mt-2">
                <dt class="col-sm-6 text-right ">
                  {{ t('Tạm tính') }}:
                </dt>
                <dd class="col-sm-6 text-right  font-medium-2 font-weight-bold">
                  <b-form-group>
                    <b-form-input
                      id="readOnlyInput"
                      :value="Number(subTotal).toLocaleString()"
                      readonly
                    />
                  </b-form-group>
                </dd>
              </dl>
              <dl
                v-if="itemLocal.promotion > 0"
                class="row"
              >
                <dt class="col-sm-6 text-right">
                  {{ t('Khuyến mại (theo chương trình)') }}:
                </dt>
                <dd class="col-sm-6 text-right">
                  <b-form-group>
                    <b-form-input
                      id="readOnlyInput"
                      :value="Number(itemLocal.promotion).toLocaleString()"
                      readonly
                    />
                  </b-form-group>
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-6 text-right">
                  {{ t('Giảm giá') }}:
                </dt>
                <dd class="col-sm-6 text-right">
                  <number-input
                    v-model="itemLocal.discount"
                    :default-value="itemLocal.discount"
                  />
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-6 text-right">
                  {{ t('Thuế') }} (%):
                </dt>
                <dd class="col-sm-6 text-right">
                  <number-input
                    v-model="itemLocal.tax"
                    :default-value="itemLocal.tax"
                  />
                </dd>
              </dl>

              <dl class="row">
                <dt class="col-sm-6 text-right">
                  {{ t('Thành tiền') }}:
                </dt>
                <dd class="col-sm-6 text-right font-medium-2 font-weight-bold">
                  <b-form-group>
                    <b-form-input
                      id="readOnlyInput"
                      :value="Number(itemLocal.total).toLocaleString()"
                      readonly
                    />
                  </b-form-group>
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-6 text-right">
                  {{ t('Trả trước') }}
                  <feather-icon
                    v-b-tooltip.hover.v-warning
                    v-b-modal.prepaid-detail-modal
                    :title="t('Trả trước là khoản tiền được lấy từ tiền thừa mà khách hàng thanh toán trước đó')"
                    class="mr-25 text-warning"
                    icon="InfoIcon"
                  />:
                </dt>
                <dd class="col-sm-6 text-right">

                  <validation-provider
                    #default="validationContext"
                    name="prepaid"
                    :rules="`required|maxValue:${maxPrepaid}`"
                  >
                    <number-input
                      v-model="itemLocal.prepaid"
                      :default-value="itemLocal.prepaid"
                      :state="getValidationState(validationContext)"
                      :error="validationContext.errors[0]"
                      :disabled="maxPrepaid <= 0"
                    />

                  </validation-provider>
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-6 text-right">
                  {{ t('Còn lại') }}:
                </dt>
                <dd class="col-sm-6 text-right font-medium-2 font-weight-bold">
                  <b-form-group>
                    <b-form-input
                      id="readOnlyInput"
                      :value="Number(itemLocal.total - itemLocal.prepaid).toLocaleString()"
                      readonly
                    />
                  </b-form-group>
                </dd>
              </dl>

            </b-col>
          </b-row>

          <hr class="mt-4">
          <b-row>
            <b-col cols="12">
              <b-form-group :label="t('Ghi chú')">
                <b-form-textarea
                  id="note"
                  v-model="itemLocal.note"
                  placeholder=""
                  rows="3"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>

      </b-form>
    </validation-observer>

    <fee-modal
      :contract="itemLocal.contract"
      :item-local="itemLocal"
      :fees-selected="itemLocal.items"
      :apartment="itemLocal.apartment"
      @selectService="onAddFee"
    />
    <prepaid-detail :item="{contract: itemLocal.contract, contractId: itemLocal.contract ? itemLocal.contract.id : 0}" />
  </b-modal>

</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  VBTooltip,
  VBModal,
  BAlert,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, maxValue } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectRoom from '@/views/components/SelectRoom.vue';
import SelectBed from '@/views/components/SelectBed.vue';
import SelectDate from '@/views/components/SelectDate.vue';
import SelectContract from '@/views/components/SelectContract.vue';
import NumberInput from '@/views/components/NumberInput.vue';
import SelectMonth from '@/views/components/SelectMonth.vue';
import SelectInvoiceType from '@/views/components/SelectInvoiceType.vue';
import { parseDateToString } from '@/auth/utils';
import useInvoiceModal from './useInvoiceModal';
import FeeTable from './FeeTable.vue';
import FeeModal from './feeModal/FeeModal.vue';
import PrepaidDetail from '../../prepaid/detail/PrepaidDetail.vue';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BAlert,
    SelectApartment,
    SelectRoom,
    SelectBed,
    SelectContract,
    SelectDate,
    FeeTable,
    NumberInput,
    FeeModal,
    SelectMonth,
    PrepaidDetail,
    SelectInvoiceType,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      maxValue,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      subTotal,
      maxPrepaid,
      isSubmitting,
      isTingTong,
      onAddFee,
      resetItemLocal,
      resetModal,
      invoiceName,
      onSubmit,
      calculateTotalForInvoiceItem,
      reCalculateQuantityAndTotal,
      resolveColWidthIfDisableBed,
      t,
      isLoading,
      onOpen,
    } = useInvoiceModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      subTotal,
      invoiceName,
      maxPrepaid,
      isSubmitting,
      isTingTong,
      onAddFee,
      resetItemLocal,
      resetModal,
      onSubmit,
      calculateTotalForInvoiceItem,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      reCalculateQuantityAndTotal,
      resolveColWidthIfDisableBed,
      t,
      parseDateToString,
      isLoading,
      onOpen,
    };
  },
};
</script>
