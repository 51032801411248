/* eslint-disable camelcase */
import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import invoiceStoreModule from '../invoiceStoreModule';

export default function usePaymentHistoryModal(props) {
  const STORE_MODULE_NAME = 'invoice';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, invoiceStoreModule);
  }

  const toastification = toast();
  const { t } = useI18nUtils();
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };
  const resetModal = () => {
    resetItemLocal();
  };

  const columns = [
    {
      label: t('Mã thu chi'),
      field: 'code',
      sortable: false,
    },
    {
      label: t('Số tiền'),
      field: 'amount',
      sortable: false,
    },
    {
      label: t('Ngày thanh toán'),
      field: 'issueDate',
      sortable: false,
    },
  ];
  const rows = ref([]);

  const fetchIncomeExpenseForInvoice = () => {
    store
      .dispatch('invoice/getIncomeExpenseForInvoice', itemLocal.value.id)
      .then(response => {
        rows.value = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const showModal = () => {
    resetModal();
    fetchIncomeExpenseForInvoice();
  };

  return {
    itemLocal,
    resetItemLocal,
    resetModal,
    showModal,
    columns,
    rows,
    t,
  };
}
