<template>
  <b-form-group
    :label="label"
  >
    <template
      v-if="label"
      v-slot:label
    >
      {{ label }}
      <span
        v-if="required"
        class="text-danger"
      >
        (*)
      </span>
    </template>
    <v-select
      id="status"
      v-model="option"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="title"
      :options="showFullOptions ? fullOptions : options"
      :placeholder="t('Loại hoá đơn')"
      @input="valueChanged"
    ><!-- eslint-disable-next-line vue/no-unused-vars  -->
      <template #no-options="{ search, searching, loading }">
        {{ t('Không có dữ liệu') }}
      </template></v-select>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    showFullOptions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      option: this.defaultValue,
      options: [
        { title: 'Hóa đơn hàng tháng', id: '2', value: '2' },
        { title: 'Hóa đơn chốt chuyển/thanh lý/nhượng', id: '7', value: '7' },
      ],
      fullOptions: [
        { title: 'Hóa đơn hợp đồng mới', id: '1', value: '1' },
        { title: 'Hóa đơn hàng tháng', id: '2', value: '2' },
        { title: 'Hóa đơn thanh lý', id: '3', value: '3' },
        { title: 'Hóa đơn chuyển phòng', id: '4', value: '4' },
        { title: 'Hóa đơn nhượng phòng', id: '5', value: '5' },
        { title: 'Hóa đơn gia hạn', id: '6', value: '6' },
        { title: 'Hóa đơn chốt chuyển/thanh lý/nhượng', id: '7', value: '7' },
        { title: 'Hóa đơn đặt cọc', id: '8', value: '8' },
      ],
    };
  },
  methods: {
    valueChanged() {
      this.$emit('input', this.option);
    },
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
</style>
