/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
import { ref, computed, watch } from '@vue/composition-api';
import store from '@/store';
import moment from 'moment';
import toast from '@/utils/toast';
import {
  getDueDateForInvoice,
  getUserConfiguration, isEnableBed, parseDateToMonthString, parseDateToString,
} from '@/auth/utils';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import invoiceStoreModule from '../invoiceStoreModule';

export default function useInvoiceModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'invoice';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, invoiceStoreModule);
  }
  const { t } = useI18nUtils();

  const toastification = toast();
  const isSubmitting = ref(false);
  const isLoading = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const isTingTong = process.env.VUE_APP_PROVIDER === 'tingtong';
  const contract = computed(() => itemLocal.value.contract);
  const month = computed(() => itemLocal.value.month);
  const finalDate = computed(() => itemLocal.value.finalDate);
  const typeObject = computed(() => itemLocal.value.typeObject);
  const remainPrepaid = ref(0);
  const maxPrepaid = ref(0);
  const subTotal = computed(() => {
    if (itemLocal.value.items && itemLocal.value.items.length > 0) {
      let totalAmount = 0;
      itemLocal.value.items.forEach(fee => {
        totalAmount += Number(fee.total) || 0;
      });
      itemLocal.value.subTotal = totalAmount;

      return itemLocal.value.subTotal;
    }
    return 0;
  });
  const discount = computed(() => itemLocal.value.discount);
  const tax = computed(() => itemLocal.value.tax);

  const invoiceName = computed(() => `Hóa đơn tháng ${itemLocal.value.month}`);
  const itemId = computed(() => (props.item && props.item.id ? props.item.id : null));
  const fetchDetailData = () => {
    if (itemId.value) {
      isLoading.value = true;
      store.dispatch('invoice/getInvoiceDetail', itemId.value)
        .then(response => {
          itemLocal.value = response.data;
          isLoading.value = false;
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = true;
        });
    }
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    if (itemLocal.value.id && itemLocal.value.id > 0 && itemLocal.value.approve) {
      const data = {
        month: moment(itemLocal.value.month, "MM-YYYY", true).isValid() ? itemLocal.value.month : moment(itemLocal.value.month).format('MM-YYYY'),
        issueDate: parseDateToString(itemLocal.value.issueDate),
        dueDate: parseDateToString(itemLocal.value.dueDate),
        invoiceId: itemLocal.value.id,
        note: itemLocal.value.note
      };
      store
        .dispatch('invoice/updateInvoiceTime', data)
        .then(response => { emit('on-item-updated', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
      return;
    }

    let data = {
      name: itemLocal.value.name,
      note: itemLocal.value.note,
      discount: Number(itemLocal.value.discount),
      promotion: Number(itemLocal.value.promotion),
      prepaid: Number(itemLocal.value.prepaid),
      tax: Number(itemLocal.value.tax),
      total: Number(itemLocal.value.total),
      subTotal: Number(itemLocal.value.subTotal),
      month: moment(itemLocal.value.month, "MM-YYYY", true).isValid() ? itemLocal.value.month : moment(itemLocal.value.month).format('MM-YYYY'),
    };
    if (itemLocal.value.typeObject) {
      data.type = itemLocal.value.typeObject.id;
    }

    if (isTingTong && itemLocal.value.items && itemLocal.value.items.filter(obj => obj.feeTypeId === 'lease').length === 0 && itemLocal.value.items.filter(obj => obj.feeItems && obj.feeItems.feeCategoryId === 'lease').length === 0) {
      data.promotion = 0;
    }

    if (itemLocal.value && itemLocal.value.id && itemLocal.value.isLiquidInvoice) {
      data = {
        note: itemLocal.value.note,
        refundDeposit: itemLocal.value.refundDeposit,
        refundPrepaid: itemLocal.value.refundPrepaid,
        refundRoomAmount: itemLocal.value.refundRoomAmount,
        penaltyFee: itemLocal.value.penaltyFee,
        month: moment(itemLocal.value.month, "MM-YYYY", true).isValid() ? itemLocal.value.month : moment(itemLocal.value.month).format('MM-YYYY'),
        isLiquidInvoice: true,
      };
    } else {
      if (itemLocal.value.apartment && itemLocal.value.apartment.id > 0) {
        data.apartmentId = itemLocal.value.apartment.id;
      }
      if (itemLocal.value.room && itemLocal.value.room.id > 0) {
        data.roomId = itemLocal.value.room.id;
      }
      if (itemLocal.value.bed && itemLocal.value.bed.id > 0) {
        data.bedId = itemLocal.value.bed.id;
      }
      if (itemLocal.value.contract && itemLocal.value.contract.id > 0) {
        data.contractId = itemLocal.value.contract.id;
      }
      if (itemLocal.value.items && itemLocal.value.items.length > 0) {
        data.items = itemLocal.value.items.map(fee => ({
          ...fee,
          startDate: moment(fee.startDate, 'DD-MM-YYYY', true).isValid() ? fee.startDate : moment(fee.startDate).format('DD-MM-YYYY'),
          endDate: moment(fee.endDate, 'DD-MM-YYYY', true).isValid() ? fee.endDate : moment(fee.endDate).format('DD-MM-YYYY'),
        }));
      }
    }

    data.issueDate = parseDateToString(itemLocal.value.issueDate);
    data.dueDate = parseDateToString(itemLocal.value.dueDate);
    data.finalDate = parseDateToString(itemLocal.value.finalDate);

    isSubmitting.value = true;

    if (itemLocal.value.id && itemLocal.value.id > 0) {
      data.id = itemLocal.value.id;
      store
        .dispatch('invoice/updateInvoice', data)
        .then(response => { emit('on-item-updated', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } else {
      store
        .dispatch('invoice/createInvoice', data)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    }
  };

  const reCalculateTotal = () => {
    const totalAmount = Number(itemLocal.value.subTotal - itemLocal.value.discount - itemLocal.value.promotion) || 0;
    const taxValue = Number(itemLocal.value.tax) / 100 || 0;
    itemLocal.value.total = totalAmount + totalAmount * taxValue;

    if (props.item && props.item.id > 0 && props.item.prepaid > 0) {
      maxPrepaid.value = Math.min(remainPrepaid.value + props.item.prepaid, Math.max(itemLocal.value.total, 0));
    } else {
      maxPrepaid.value = Math.min(remainPrepaid.value, Math.max(itemLocal.value.total, 0));
    }

    if (!props.item.id || props.item.id === 0) {
      itemLocal.value.prepaid = Math.min(Math.max(itemLocal.value.subTotal, 0), maxPrepaid.value);
    }
  };

  const getRemainPrepaidByContract = val => {
    if (val && val.id > 0) {
      store.dispatch('prepaid/getPrepaidRemainByContract', { contractId: val.id })
        .then(response => {
          remainPrepaid.value = response.data;
          if ((!props.item.id || props.item.id === 0) && itemLocal.value.subTotal > 0) {
            itemLocal.value.prepaid = Math.min(Math.max(itemLocal.value.subTotal, 0), response.data);
          }
          reCalculateTotal();
        })
        .catch(error => {
          toastification.showToastError(error);
        });
    }
  };

  const calculateTotalForInvoiceItem = invoiceItem => {
    const newInvoiceItem = { ...invoiceItem };
    let tmp_subTotal = 0;
    let tmp_total = 0;

    const {
      feeItems, meterLogItems, quotaItems, coefficient, quantity, price,
    } = newInvoiceItem;

    const coefficientNumber = Number(coefficient) || 0;
    let quantityNumber = Number(quantity) || 0;
    const priceNumber = Number(price) || 0;

    // kiem tra neu ton tai feeItems thi tinh toan
    if (!feeItems) {
      return { ...newInvoiceItem, subTotal: 0, total: 0 };
    }

    // Nếu là đơn giá cố định theo đồng hồ
    if (feeItems.type.id === 1) {
      // Kiểm tra xem có hay chưa đồng hồ
      // if (!meterItems) {
      //   //
      //   return { ...newInvoiceItem, subTotal: 0, total: 0 };
      // }

      //

      // Kiểm tra xem đã có chỉ số công tơ hay chưa
      if (!meterLogItems) {
        //
        return { ...newInvoiceItem, subTotal: 0, total: 0 };
      }

      // Tính toán dựa trên công thức: đơn giá x tiêu thụ x hệ số
      quantityNumber = Number(meterLogItems.delta) || 0;
      newInvoiceItem.quantity = quantityNumber;

      tmp_subTotal = priceNumber * coefficientNumber * quantityNumber;
      tmp_total = tmp_subTotal;
    } else if (feeItems.type.id === 2) {
      // TH với đơn giá định mức theo đồng hồ

      if (!meterLogItems) {
        //
        return { ...newInvoiceItem, subTotal: 0, total: 0 };
      }

      if (!quotaItems) {
        //
        return { ...newInvoiceItem, subTotal: 0, total: 0 };
      }

      // Tính toán dựa trên công thức: đơn giá x tiêu thụ x hệ số
      quantityNumber = Number(meterLogItems.delta) || 0;
      newInvoiceItem.quantity = quantityNumber;

      let totalDelta = quantityNumber;
      let totalAmount = 0;

      // eslint-disable-next-line no-restricted-syntax
      for (const quotaItem of quotaItems.items) {
        if (totalDelta > 0) {
          totalAmount += Math.min(quotaItem.quantity, totalDelta) * quotaItem.price;
          totalDelta -= Math.min(quotaItem.quantity, totalDelta);
        } else {
          break;
        }
      }
      tmp_subTotal = totalAmount * coefficientNumber;
      tmp_total = tmp_subTotal;
    } else if (feeItems.type.id === 3) {
      // TH với đơn giá định cố định theo tháng

      // Tính toán dựa trên công thức: đơn giá x tiêu thụ x hệ số
      tmp_subTotal = priceNumber * coefficientNumber * quantityNumber;
      tmp_total = tmp_subTotal;
    } else if (feeItems.type.id === 4) {
      // TH với đơn giá biến động theo tháng

      // Tính toán dựa trên công thức: đơn giá biến động x tiêu thụ x hệ số
      tmp_subTotal = priceNumber * coefficientNumber * quantityNumber;
      tmp_total = tmp_subTotal;
    } else if (feeItems.type.id === 5) {
      // TH với đơn giá định mức theo số lượng

      if (!quotaItems) {
        //
        return { ...newInvoiceItem, subTotal: 0, total: 0 };
      }

      // Tính toán dựa trên công thức: đơn giá x tiêu thụ x hệ số

      let totalDelta = quantity;
      let totalAmount = 0;

      // eslint-disable-next-line no-restricted-syntax
      for (const quotaItem of quotaItems.items) {
        if (totalDelta > 0) {
          totalAmount += Math.min(quotaItem.quantity, totalDelta) * quotaItem.price;
          totalDelta -= Math.min(quotaItem.quantity, totalDelta);
        } else {
          break;
        }
      }
      tmp_subTotal = totalAmount * coefficientNumber;
      tmp_total = tmp_subTotal;
    }

    newInvoiceItem.subTotal = Number(tmp_subTotal.toFixed(0));
    newInvoiceItem.total = Number(tmp_total.toFixed(0));

    return newInvoiceItem;
  };

  const calculateRemainDiscount = quantityValue => {
    if (!props.item.id || props.item.id === 0) {
      if (itemLocal.value.typeObject && parseInt(itemLocal.value.typeObject.id, 10) === 7) {
        itemLocal.value.promotion = 0;
        return;
      }

      let discountQuantityMonth = quantityValue ? parseInt(quantityValue, 10) : 1;

      if (discountQuantityMonth < 1) {
        discountQuantityMonth = 1;
      }

      store.dispatch('contract/getRemainDiscount', contract.value.id).then(response => {
        const remainDiscount = response.data;
        if (remainDiscount > 0) {
          // Kiểm tra xem có hóa đơn tiền nhà không, nếu có thì giảm giá theo số lượng tiền nhà, không thì giảm giá theo tiền dịch vụ
          if (itemLocal.value.finalDate) {
            // Lấy dịch vụ không phải tính theo đồng hồ
            const filterItems = itemLocal.value.items.filter(object => ![1, 2].includes(object.feeItems.type.id));
            if (filterItems && filterItems.length > 0) {
              itemLocal.value.promotion = Number(Math.min(remainDiscount, contract.value.promotionPricePerMonth * discountQuantityMonth));
            }
          } else {
            const filterItems = itemLocal.value.items.filter(object => object.feeItems.feeCategoryId === 'lease');
            if (filterItems && filterItems.length > 0) {
              itemLocal.value.promotion = Number(Math.min(remainDiscount, contract.value.promotionPricePerMonth * discountQuantityMonth));
            }
          }

          // itemLocal.value.promotion = 200000;
        }
      });
    }
  };

  const convertFeeToInvoiceItem = (feeObject, meter) => {
    const feeItem = { ...feeObject };
    const meterItem = meter || null;
    const meterLogItem = null;
    const quotaItem = feeObject.quota || null;
    let price = feeItem.price || 0;
    let quantityItem = feeItem.quantity >= 0 ? feeItem.quantity : 1;
    let startDate = moment(feeItem.startDate, 'DD-MM-YYYY').isValid() ? moment(feeItem.startDate, 'DD-MM-YYYY') : moment(new Date());
    let endDate = startDate.clone().endOf('month');
    let coefficient = feeObject.coefficient ? feeObject.coefficient : 1;
    // Tính toán lại end date
    const userConfiguration = getUserConfiguration();
    if (userConfiguration.serviceFeeCalculateType === 'in-month') {
      endDate = startDate.clone().endOf('month');
    } else if (userConfiguration.serviceFeeCalculateType === 'full') {
      endDate = startDate.clone().add(1, 'month').subtract(1, 'd');
    } else if (userConfiguration.serviceFeeCalculateType === 'by-payment-day') {
      const { paymentDay } = contract.value;
      if (paymentDay >= 1 && paymentDay <= 31) {
        endDate = startDate.clone().startOf('month').add(paymentDay, 'day').subtract(1, 'day');
        if (endDate.isSameOrBefore(startDate)) {
          endDate = startDate.clone().startOf('month').add(1, 'month').add(paymentDay, 'day')
            .subtract(1, 'day');
        }
      }
    }

    if (isTingTong && moment(parseDateToMonthString(month.value), "MM-YYYY").isAfter(endDate.clone().startOf('month'))) {
      endDate = moment(parseDateToMonthString(month.value), "MM-YYYY").startOf('month').add(endDate.date() - 1, 'days');
    }

    let shouldCalculateQuantity = false;
    if (userConfiguration && userConfiguration.allowContractPaymentDayConfig && contract.value && Number(contract.value.paymentDay) >= 1 && Number(contract.value.paymentDay) <= 31 && feeObject.feeCategoryId === 'lease') {
      startDate = moment(itemLocal.value.contract.lastBillingDate).isValid() ? moment(itemLocal.value.contract.lastBillingDate).add(1, 'd') : moment(itemLocal.value.contract.billingDate);
      endDate = startDate.clone().add(itemLocal.value.contract.paymentPeriod.value, 'month').subtract(1, 'days');
      if (userConfiguration && userConfiguration.allowContractPaymentDayConfig && contract.value && Number(contract.value.paymentDay) >= 1 && Number(contract.value.paymentDay) <= 31) {
        endDate = startDate.clone().startOf('month').add(contract.value.paymentDay - 1, 'days');
        if (Number(contract.value.paymentDay) === 31) {
          if (startDate.date() === 1) {
            endDate = startDate.clone().add(Number(itemLocal.value.contract.paymentPeriod.value) - 1, 'months').endOf('month');
          } else {
            endDate = startDate.clone().startOf('month').add(Number(itemLocal.value.contract.paymentPeriod.value), 'months').endOf('month');
          }
        } else if (endDate.clone().add(1, 'days').isBefore(startDate)) {
          endDate = endDate.add(Number(itemLocal.value.contract.paymentPeriod.value) + 1, 'months');
        } else {
          endDate = endDate.add(Number(itemLocal.value.contract.paymentPeriod.value), 'months');
        }
      }
      shouldCalculateQuantity = true;
    }

    const calculatedCoefficient = new Promise(resolve => {
      if ((userConfiguration.autoCalculateCoefficientByDate && !['lease', 'deposit', 'move', 'transfer'].includes(feeObject.feeCategoryId) && ![1, 2].includes(parseInt(feeObject.typeId, 10))) || shouldCalculateQuantity) {
        store
          .dispatch('invoice/calculateQuantity', {
            startDate: parseDateToString(startDate), endDate: parseDateToString(endDate), contractId: contract.value.id, feeId: feeObject.id,
          })
          .then(response => {
            const { quantity } = response.data;
            resolve(Number(Number(quantity).toFixed(2)));
          })
          .catch(error => {
            toastification.showToastError(error, refForm.value);
            resolve(1);
          });
      } else {
        resolve(coefficient);
      }
    });

    calculatedCoefficient.then(newCoefficient => {
      coefficient = newCoefficient;
      let totalAmount = 0;

      if (itemLocal.value.contract) {
        if (feeObject.feeCategoryId === 'lease') {
          if (shouldCalculateQuantity) {
            quantityItem = Number(newCoefficient);
            coefficient = 1;
          } else {
            // Trong TH phi dich vu la tien nha thi tinh toan lai ngay bat dau, ngay ket thuc va so luong
            startDate = moment(itemLocal.value.contract.lastBillingDate).isValid() ? moment(itemLocal.value.contract.lastBillingDate).add(1, 'd') : moment(itemLocal.value.contract.billingDate);
            endDate = startDate.clone().add(itemLocal.value.contract.paymentPeriod.value, 'month').subtract(1, 'days');
            if (userConfiguration && userConfiguration.allowContractPaymentDayConfig && contract.value && Number(contract.value.paymentDay) >= 1 && Number(contract.value.paymentDay) <= 31) {
              endDate = startDate.clone().startOf('month').add(contract.value.paymentDay - 1, 'days');
              if (endDate.clone().add(1, 'days').isBefore(startDate)) {
                endDate = endDate.add(Number(itemLocal.value.contract.paymentPeriod.value) + 1, 'months');
              } else {
                endDate = endDate.add(Number(itemLocal.value.contract.paymentPeriod.value), 'months');
              }
            }

            quantityItem = Number(itemLocal.value.contract.paymentPeriod.value);
          }

          if (isTingTong) {
            if (itemLocal.value.contract.promotionDeposit > 0) {
              if (itemLocal.value.contract.priceBeforeExtend > 0 && moment(itemLocal.value.contract.dateBeforeExtend).isValid() && startDate.isBefore(moment(itemLocal.value.contract.dateBeforeExtend))) {
                price = itemLocal.value.contract.priceBeforeExtend - itemLocal.value.contract.promotionDeposit;
              } else {
                price = itemLocal.value.contract.price - itemLocal.value.contract.promotionDeposit;
              }
            } else if (itemLocal.value.contract.priceBeforeExtend > 0 && moment(itemLocal.value.contract.dateBeforeExtend).isValid() && startDate.isBefore(moment(itemLocal.value.contract.dateBeforeExtend))) {
              price = itemLocal.value.contract.priceBeforeExtend;
            } else {
              price = itemLocal.value.contract.price;
            }
          } else {
            price = itemLocal.value.contract.price;
          }

          totalAmount = Number(price) * Number(quantityItem);
        } else if (feeObject.feeCategoryId === 'deposit') {
          // Trong TH phi dich vu la tien coc thi tinh toan lai so luong
          quantityItem = 1;
          if (itemLocal.value.contract.reservation) {
            price = Math.max(itemLocal.value.contract.deposit - itemLocal.value.contract.reservation.deposit, 0);
          } else {
            price = itemLocal.value.contract.deposit;
          }
          if (isTingTong && itemLocal.value.contract.promotionDeposit > 0) {
            price -= parseInt((itemLocal.value.contract.deposit / itemLocal.value.contract.price).toString(), 10) * itemLocal.value.contract.promotionDeposit;
          }

          totalAmount = Number(price) * Number(quantityItem);
        }
      }

      if (feeItem.feeCategoryId === 'lease') {
        calculateRemainDiscount(quantityItem);
      }

      const invoiceItem = {
        name: feeItem.name || '',
        quantity: quantityItem,
        coefficient,
        price,
        tax: 0,
        taxEnable: false,
        discount: 0,
        subTotal: totalAmount,
        total: totalAmount,
        startDate: startDate.format("DD-MM-YYYY"),
        endDate: endDate.format("DD-MM-YYYY"),

        feeItems: feeItem,
        meterItems: meterItem,
        meterLogItems: meterLogItem,
        quotaItems: quotaItem,
      };

      const newInvoiceItem = calculateTotalForInvoiceItem(invoiceItem);

      // Kiểm tra xem đã có dịch vụ hay chưa, nếu chưa có thì thêm vào mảng các phí dịch vụ
      if (itemLocal.value && itemLocal.value.items && itemLocal.value.items.length > 0) {
        const count = itemLocal.value.items.filter(eachItem => eachItem.feeItems && newInvoiceItem.feeItems && eachItem.feeItems.id === newInvoiceItem.feeItems.id).length;
        if (count === 0) {
          itemLocal.value.items = [...itemLocal.value.items, newInvoiceItem];
        }
      } else {
        itemLocal.value.items = [newInvoiceItem];
      }

      return newInvoiceItem;
    });
  };

  const onAddFee = listServiceSelected => {
    listServiceSelected.map(fee => {
      // Kiểm tra xem fee có nằm trong danh sách đăng ký sử dụng của hợp đồng hay không. Nếu có thì lấy thông tin trong hợp đồng để cho chuẩn về ngày tháng kế tiếp

      if (itemLocal.value.contract && itemLocal.value.contract.contractFees) {
        const filterObjects = itemLocal.value.contract.contractFees.filter(_obj => _obj.fee && _obj.fee.id === fee.id);
        if (filterObjects && filterObjects.length > 0) {
          const feeObject = filterObjects[0];
          const lastBillingDate = moment(feeObject.lastBillingDate).isValid() ? moment(feeObject.lastBillingDate).add(1, 'd').format('DD-MM-YYYY') : '';
          return convertFeeToInvoiceItem({
            ...feeObject.fee, quantity: feeObject.quantity, coefficient: feeObject.coefficient, startDate: lastBillingDate,
          }, feeObject.meter);
        }
      }

      return convertFeeToInvoiceItem(fee);
    });
  };

  const addLeaseAndDepositIfNeeded = () => {
    if (contract.value && month.value && itemLocal.value.id === 0) {
      const monthOfBillingDate = moment(contract.value.lastBillingDate).startOf('M');

      if (moment(month.value, 'MM-YYYY').isSameOrAfter(monthOfBillingDate)) {
        store.dispatch('fee/fetchFees')
          .then(response => {
            if (contract.value.numberInvoices && contract.value.numberInvoices >= 1) {
              onAddFee(response.data.items.filter(_obj => _obj.feeCategoryId === 'lease'));
            } else {
              let remainDepositPrice = 0;
              if (itemLocal.value.contract.reservation) {
                remainDepositPrice = Math.max(itemLocal.value.contract.deposit - itemLocal.value.contract.reservation.deposit, 0);
              } else {
                remainDepositPrice = itemLocal.value.contract.deposit;
              }
              if (remainDepositPrice > 0) {
                onAddFee(response.data.items.filter(_obj => _obj.feeCategoryId === 'lease' || _obj.feeCategoryId === 'deposit'));
              } else {
                onAddFee(response.data.items.filter(_obj => _obj.feeCategoryId === 'lease'));
              }
            }
          })
          .catch(error => {
            toastification.showToastError(error);
          });
      }
    }
  };

  const reCalculateQuantityAndTotal = () => {
    // Kiem tra xem co loai phi dich vu nao la tien nha hay khong
    itemLocal.value.items.forEach((invoiceItem, index) => {
      if (invoiceItem.feeItems.feeCategoryId === 'lease') {
        store
          .dispatch('invoice/calculateQuantity', { startDate: parseDateToString(invoiceItem.startDate), endDate: parseDateToString(invoiceItem.endDate), contractId: contract.value.id })
          .then(response => {
            const { quantity } = response.data;
            itemLocal.value.items[index].quantity = Number(Number(quantity).toFixed(2));
            // itemLocal.value.items[index].totalDays = totalDays;
            itemLocal.value.items[index].subTotal = parseInt(((quantity) * itemLocal.value.items[index].price).toFixed(0), 10);
            itemLocal.value.items[index].total = parseInt(((quantity) * itemLocal.value.items[index].price).toFixed(0), 10);
          })
          .catch(error => {
            toastification.showToastError(error, refForm.value);
          });
      }
    });
  };

  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
    getRemainPrepaidByContract(contract.value);
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const onOpen = () => {
    resetModal();
    fetchDetailData();
  };

  const addContractFeeIfNeeded = () => {
    const userConfiguration = getUserConfiguration();
    const isVmarket = process.env.VUE_APP_PROVIDER === 'vmarket';
    if (contract.value && contract.value.contractFees && contract.value.contractFees.length > 0) {
      for (let index = 0; index < contract.value.contractFees.length; index++) {
        const eachContractFee = contract.value.contractFees[index];
        const startDateForFee = moment(eachContractFee.lastBillingDate).isValid() ? moment(eachContractFee.lastBillingDate).add(1, 'd').format('DD-MM-YYYY') : null;
        const momentMonth = moment(itemLocal.value.month, "MM-YYYY", true).isValid() ? moment(itemLocal.value.month, "MM-YYYY", true) : moment(itemLocal.value.month);
        if (((userConfiguration && userConfiguration.autoCalculateCoefficientByDate) || isVmarket) && momentMonth.isValid() && moment(startDateForFee, "DD-MM-YYYY", true).isValid() && moment(startDateForFee, "DD-MM-YYYY", true).startOf('month').isAfter(momentMonth) && eachContractFee.fee && !['1', '2'].includes(eachContractFee.fee.typeId)) {
          // chưa đến kỳ thanh toán
        } else {
          convertFeeToInvoiceItem({
            ...eachContractFee.fee, quantity: eachContractFee.quantity, coefficient: eachContractFee.coefficient, startDate: startDateForFee,
          }, eachContractFee.meter);
        }
      }
    } else {
      //
    }
  };

  watch(contract, val => {
    if (val) {
      // Tính toán cấu hình giảm giá
      calculateRemainDiscount();

      // Lấy số tiền dư của khách
      getRemainPrepaidByContract(val);

      // Tính toán lại hạn thanh toán hóa đơn
      if (!itemLocal.value.id || itemLocal.value.id === 0) {
        const newDueDue = getDueDateForInvoice(val, itemLocal.value.month);
        itemLocal.value.dueDate = newDueDue;
      }

      addLeaseAndDepositIfNeeded();
      addContractFeeIfNeeded();

      // if (val.contractFees && val.contractFees.length > 0) {
      //   val.contractFees.map(contractFee => {
      //     const startDateForFee = moment(contractFee.lastBillingDate).isValid() ? moment(contractFee.lastBillingDate).add(1, 'd').format('DD-MM-YYYY') : null;
      //     const momentMonth = moment(itemLocal.value.month, "MM-YYYY", true).isValid() ? moment(itemLocal.value.month, "MM-YYYY", true) : moment(itemLocal.value.month);
      //     if (momentMonth.isValid() && moment(startDateForFee, "DD-MM-YYYY", true).isValid() && moment(startDateForFee, "DD-MM-YYYY", true).startOf('month').isAfter(momentMonth)) {
      //       return null;
      //     }
      //     const newInvoiceItem = convertFeeToInvoiceItem({
      //       ...contractFee.fee, quantity: contractFee.quantity, coefficient: contractFee.coefficient, startDate: startDateForFee,
      //     }, contractFee.meter);

      //     //

      //     return newInvoiceItem;
      //   });
      //   if (val.bed && !itemLocal.value.bed) {
      //     // itemLocal.value.bed = val.bed;
      //   }
      // }
    } else {
      itemLocal.value.items = [];
    }
  });

  watch(month, val => {
    // Kiểm tra nếu như lastBillingDate <= tháng hiện tại đang chọn thì sẽ thêm tiền nhà vào
    addLeaseAndDepositIfNeeded(val);
    addContractFeeIfNeeded();
    const newDueDue = getDueDateForInvoice(contract.value, val);
    itemLocal.value.dueDate = newDueDue;

    //  tính toán ngày lập với tingtong, mặc định ngày 28
    if (isTingTong && moment(val, 'MM-YYYY', true).isValid()) {
      itemLocal.value.issueDate = moment(val, 'MM-YYYY', true).subtract(1, 'month').add(27, 'day').toDate();
    }
  });

  watch(subTotal, () => {
    reCalculateTotal();
  });

  watch(discount, () => {
    reCalculateTotal();
  });

  watch(tax, () => {
    reCalculateTotal();
  });

  watch(typeObject, val => {
    if (!val || val.id !== '7') {
      itemLocal.value.finalDate = null;
    }
    if (val && parseInt(val.id, 10) === 7) {
      itemLocal.value.promotion = 0;
    }
  });

  watch(finalDate, val => {
    if (val) {
      if (isTingTong) {
        itemLocal.value.issueDate = val;
      }
      // Hóa đơn chốt thì không có tiền khuyến mại

      const userConfiguration = getUserConfiguration();

      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < itemLocal.value.items.length; index++) {
        itemLocal.value.items[index].endDate = val;
        if (userConfiguration.autoCalculateCoefficientByDate && !['deposit', 'move', 'transfer'].includes(itemLocal.value.items[index].feeItems.feeCategoryId) && ![1, 2].includes(parseInt(itemLocal.value.items[index].feeItems.typeId, 10))) {
          store
            .dispatch('invoice/calculateQuantity', { startDate: parseDateToString(itemLocal.value.items[index].startDate), endDate: parseDateToString(val), contractId: contract.value.id })
            .then(response => {
              const { quantity } = response.data;

              let newItem = itemLocal.value.items[index];
              if (itemLocal.value.items[index].feeItems.feeCategoryId === 'lease') {
                newItem.quantity = quantity;
              } else {
                newItem.coefficient = quantity;
              }

              newItem = calculateTotalForInvoiceItem(itemLocal.value.items[index]);

              itemLocal.value.items.splice(index, 1, newItem);
            });
        }
      }
    }
    calculateRemainDiscount();
  });

  const resolveColWidthIfDisableBed = isBed => {
    if (isEnableBed()) {
      return 3;
    }
    if (isBed) {
      return 0;
    }
    return 4;
  };

  return {
    refModal,
    refForm,
    itemLocal,
    subTotal,
    invoiceName,
    maxPrepaid,
    isSubmitting,
    isTingTong,
    onAddFee,
    resetItemLocal,
    resetModal,
    onSubmit,
    calculateTotalForInvoiceItem,
    reCalculateQuantityAndTotal,
    resolveColWidthIfDisableBed,
    t,
    isLoading,
    onOpen,
  };
}
