<template>
  <b-modal
    id="modal-send-invoice"
    ref="refModalSendInvoice"
    :title="t('Gửi hóa đơn')"
    :ok-title="isSubmitting ? t('Đang gửi') : t('Gửi')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    scrollable
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-alert
          show
          variant="danger"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="SendIcon"
            />
            <span class="ml-25">{{ t('Hệ thống chỉ gửi hóa đơn còn nợ và đã được duyệt đến cho khách hàng. Bạn có thể tra cứu lại lịch sử gửi tại chức năng thông báo') }}</span>
          </div>
        </b-alert>
        <b-row class="mt-2">
          <b-col
            cols="12"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                1. {{ t('Tòa nhà') }}
              </div>
            </div>
          </b-col>
          <b-col
            cols="12"
            class="mt-1"
          >
            <select-apartment
              v-model="itemLocal.apartment"
              :filter-active="true"
              :default-value="itemLocal.apartment"
            />
          </b-col>

        </b-row>
        <b-row>
          <b-col
            cols="12"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                2. {{ t('Hình thức gửi') }}
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="Hình thức gửi"
              rules="required"
            >
              <b-form-checkbox-group
                v-model="itemLocal.methods"
                :disabled="itemLocal.id > 0"
                :options="[
                  { text: t('Ứng dụng Resident'), value: 'app' },
                  { text: t('Zalo OA'), value: 'zalo' },
                  { text: t('Zalo BOT'), value: 'zalo-normal' },
                  { text: t('Email'), value: 'email' },
                ]"
                class="demo-inline-spacing"
              />
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>

  </b-modal>

</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BForm,
  BAlert,
  BFormCheckboxGroup,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectApartment from '@/views/components/SelectApartment.vue';
import useInvoiceSendModal from './useInvoiceSendModal';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BForm,
    ValidationProvider,
    ValidationObserver,
    SelectApartment,
    BAlert,
    BFormCheckboxGroup,
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalSendInvoice,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      t,
    } = useInvoiceSendModal(props, emit, refFormObserver);

    return {
      refModalSendInvoice,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      t,
    };
  },
};
</script>
