/* eslint-disable camelcase */
import { getCurrentInstance, ref } from '@vue/composition-api';
import store from '@/store';
import moment from 'moment';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import invoiceStoreModule from '../invoiceStoreModule';

export default function useGenerateInvoiceModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'invoice';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, invoiceStoreModule);
  }
  const { t } = useI18nUtils();
  const toastification = toast();
  const isSubmitting = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const blankItem = {
    apartment: null,
    month: moment(new Date()).format("MM-YYYY"),
    mode: null,
  };
  const itemLocal = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(blankItem));
    isSubmitting.value = false;
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const vm = getCurrentInstance().proxy;
  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      month: moment(itemLocal.value.month, "MM-YYYY").isValid() ? itemLocal.value.month : null,
    };

    if (itemLocal.value.mode) {
      data.type = itemLocal.value.mode.id;
    }
    if (itemLocal.value.apartment && itemLocal.value.apartment.id > 0) {
      data.apartmentId = itemLocal.value.apartment.id;
    }

    isSubmitting.value = true;
    store
      .dispatch('invoice/generateMultipleInvoices', data)
      .then(response => { emit('on-item-updated', response.data); })
      .then(() => {
        refModal.value.toggle('#toggle-btn');
      })
      .then(() => {
        emit('refetch-data');
      })
      .then(() => {
        toastification.showToastSuccess('Vui lòng đợi trong giây lát, hệ thống đang tiến hành xử lý tạo hóa đơn cho bạn');
        isSubmitting.value = false;
        toastification.showToastSuccess('Vui lòng đợi chút, hệ thống đang xử lý file của bạn');
        vm.$router
          .push({ name: 'jobs' });
      })
      .catch(error => {
        toastification.showToastError(error, refForm.value);
        isSubmitting.value = false;
      });
  };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    t,
  };
}
