import { ref } from '@vue/composition-api';
import store from '@/store';

import toast from '@/utils/toast';
import moment from 'moment';
import { parseDateToString } from '@/auth/utils';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import meterStoreModule from '../../meter/meterStoreModule';
import meterLogStoreModule from '../meterLogStoreModule';

export default function useMeterLogModal(props, emit, refFormObserver) {
  const toastification = toast();
  const STORE_MODULE_NAME = 'meterLog';
  const STORE_MODULE_METER = 'meter';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, meterLogStoreModule);
  }
  if (!store.hasModule(STORE_MODULE_METER)) {
    store.registerModule(STORE_MODULE_METER, meterStoreModule);
  }
  const isSubmitting = ref(false);
  const { t } = useI18nUtils();
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const fetchMeters = () => {
    if ((itemLocal.value.meters && itemLocal.value.meters.length > 0) || !itemLocal.value.apartment || itemLocal.value.apartment.id <= 0) {
      return;
    }
    store.dispatch('meter/fetchMeters', { apartmentId: itemLocal.value.apartment.id }).then(response => {
      itemLocal.value.meters = response.data.items.map(meter => ({
        meter,
        firstIndex: meter.lastIndex,
        lastIndex: meter.smartDevice ? parseInt(meter.smartDevice.currentValue, 10) : 0,
        issueDate: moment(new Date()).toDate(),
      }));
    }).catch(error => {
      toastification.showToastError(error);
    });
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const formData = new FormData();

    let isUpdate = false;
    // eslint-disable-next-line no-restricted-syntax

    const arrayObject = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const meter of itemLocal.value.meters) {
      const meterLog = {
        approve: props.approve,
        meterId: meter.meter.id,
        firstIndex: meter.firstIndex,
        lastIndex: meter.lastIndex,
        issueDate: parseDateToString(meter.issueDate),
        month: moment(itemLocal.value.month, "MM-YYYY").isValid() ? itemLocal.value.month : moment(itemLocal.value.month).format("MM-YYYY"),
      };
      if (meter.id && meter.id > 0) {
        meterLog.id = meter.id;
        isUpdate = true;
      }
      if (meter.imageFile) {
        formData.append('images', meter.imageFile);
        meterLog.hasImage = true;
      } else {
        meterLog.hasImage = false;
      }

      arrayObject.push(meterLog);
    }

    formData.append('items', JSON.stringify(arrayObject));

    isSubmitting.value = true;

    if (isUpdate) {
      store
        .dispatch('meterLog/updateMeterLog', formData)
        .then(response => { emit('on-item-updated', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } else {
      store
        .dispatch('meterLog/createMeterLog', formData)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    }
  };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    fetchMeters,
    t,
  };
}
