import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { parseDateToString } from '@/auth/utils';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import invoiceStoreModule from '../invoiceStoreModule';

export default function useInvoicePaymentModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'invoice';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, invoiceStoreModule);
  }
  const { t } = useI18nUtils();
  const toastification = toast();
  const isSubmitting = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const openModal = () => {
    resetItemLocal();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      amount: itemLocal.value.amount,
      note: itemLocal.value.note,
      receiver: itemLocal.value.receiver,
      payer: itemLocal.value.payer,
      invoiceIds: itemLocal.value.invoices.map(invoice => invoice.id),
      isIncome: props.item.totalRemain > 0 || false,
    };

    if (props.item.totalRemain < 0 && itemLocal.value.amount > Math.abs(props.item.totalRemain)) {
      toastification.showToastErrorWithMessage('Số tiền chi không được quá số tiền tối đa');
      return;
    }

    if (itemLocal.value.cashbook && itemLocal.value.cashbook.id > 0) {
      data.cashbookId = itemLocal.value.cashbook.id;
    }
    // attachments
    if (itemLocal.value.attachments) {
      data.attachments = itemLocal.value.attachments.map(object => object.location);
    }

    data.issueDate = parseDateToString(itemLocal.value.issueDate);

    isSubmitting.value = true;

    store
      .dispatch('invoice/createInvoicePayment', data)
      .then(response => { emit('on-item-created', response.data); })
      .then(() => {
        refModal.value.toggle('#toggle-btn');
      })
      .then(() => {
        emit('refetch-data');
      })
      .then(() => {
        toastification.showToastCreateSuccess();
        isSubmitting.value = false;
      })
      .catch(error => {
        toastification.showToastError(error, refForm.value);
        isSubmitting.value = false;
      });
  };

  const onDeleteAttachment = () => {

  };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    openModal,
    t,
    onDeleteAttachment,
  };
}
