<template>
  <div class="invoice-list-container">
    <b-row
      v-if="analytics"
      class="match-height"
    >

      <b-col>
        <card-statistic
          :title="t('Tổng tiền')"
          :value="Number(analytics.total).toLocaleString()"
          icon="PenToolIcon"
          color="secondary"
        />
      </b-col>
      <b-col v-if="!isTingTong">
        <card-statistic
          :title="t('Tiền nhà')"
          :value="Number(analytics.totalLease).toLocaleString()"
          icon="PenToolIcon"
          color="secondary"
        />
      </b-col>
      <b-col v-if="!isTingTong">
        <card-statistic
          :title="t('Tiền dịch vụ')"
          :value="Number(analytics.totalOtherFee).toLocaleString()"
          icon="PenToolIcon"
          color="secondary"
        />
      </b-col>
      <b-col>
        <card-statistic
          :title="t('Tổng tiền thu')"
          :value="Number(analytics.totalDebt).toLocaleString()"
          icon="PenToolIcon"
          color="secondary"
        />
      </b-col>
      <b-col>
        <card-statistic
          :title="t('Tổng tiền hoàn')"
          :value="Number(analytics.totalRefund).toLocaleString()"
          icon="PenToolIcon"
          color="secondary"
        />
      </b-col>

      <b-col>
        <card-statistic
          :title="t('Đã thu')"
          :value="Number(analytics.paid).toLocaleString()"
          icon="PenToolIcon"
          color="secondary"
        />
      </b-col>
      <b-col>
        <card-statistic
          :title="t('Phải thu')"
          :value="Number(analytics.remain).toLocaleString()"
          icon="PenToolIcon"
          color="secondary"
        />
      </b-col>
      <b-col v-if="month">
        <card-statistic
          :title="t('Phải thu kèm nợ cũ')"
          :value="Number(analytics.remainWithDebt).toLocaleString()"
          icon="PenToolIcon"
          color="secondary"
        />
      </b-col>

    </b-row>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ t('Hoá đơn') }}</b-card-title>
        <crud-buttons
          :name="t('Hóa đơn')"
          :selected-rows="selectedRows"
          :show-import="$can('create', 'invoice')"
          :show-export="true"
          modal="modal-invoice"
          :enable-delete="setDeleteEnable(selectedRows)"
          :hide-delete="!$can('delete', 'invoice')"
          :enable-add="$can('create', 'invoice')"
          :show-apartment="true"
          :show-month="true"
          :require-month-for-import="true"
          @on-add="resetItem"
          @on-delete="onDelete"
          @on-export="exportData"
          @on-import-file="importData"
          @download-template-file="downloadImportTemplate"
        >
          <template
            slot="other-buttons"
          >
            <!-- <b-button
              v-if="$can('create', 'invoice')"
              v-b-tooltip.hover.v-success
              v-b-modal.modal-create-multiple-invoice
              variant="success"
              class="btn-icon ml-50"
              title="Tạo nhiều"
              @click="onCreateMultiple()"
            >
              <feather-icon icon="CheckSquareIcon" />
            </b-button> -->
            <b-button
              v-if="$can('approve', 'invoice')"
              v-b-tooltip.hover.v-success
              variant="success"
              class="btn-icon ml-50"
              :title="t('Duyệt')"
              :hidden="setApproveHidden(selectedRows)"
              @click="onApproveClick(selectedRows)"
            >
              <feather-icon icon="CheckSquareIcon" />
            </b-button>
            <b-button
              v-if="$can('approve', 'invoice')"
              v-b-tooltip.hover.v-secondary
              variant="secondary"
              class="btn-icon ml-50"
              :title="t('Bỏ duyệt')"
              :hidden="setDeclineHidden(selectedRows)"
              @click="onDeclineClick(selectedRows)"
            >
              <feather-icon icon="XIcon" />
            </b-button>
            <b-button
              v-if="$can('pay', 'invoice')"
              v-b-tooltip.hover.v-warning
              v-b-modal.modal-invoice-payment
              variant="warning"
              class="btn-icon ml-50"
              :title="t('Thu tiền')"
              :hidden="setPaymentHidden(selectedRows)"
              @click="onSelectPayment(selectedRows)"
            >
              <feather-icon icon="CreditCardIcon" />
            </b-button>
            <b-button
              v-if="$can('read', 'invoice')"
              v-b-tooltip.hover.v-secondary
              variant="secondary"
              class="btn-icon ml-50"
              :title="t('Tải hóa đơn PDF')"
              @click="exportZipPdf()"
            >
              <feather-icon icon="FolderIcon" />
            </b-button>
            <b-button
              v-if="$can('read', 'invoice')"
              v-b-tooltip.hover.v-secondary
              variant="secondary"
              class="btn-icon ml-50"
              :title="t('Tải ảnh hóa đơn')"
              @click="exportZipImages()"
            >
              <feather-icon icon="ImageIcon" />
            </b-button>
            <b-button
              v-if="$can('send', 'invoice')"
              v-b-tooltip.hover.v-warning
              v-b-modal.modal-send-invoice
              variant="warning"
              class="btn-icon ml-50"
              :title="t('Gửi hóa đơn')"
            >
              <feather-icon icon="SendIcon" />
            </b-button>
            <b-button
              v-if="$can('create', 'invoice')"
              v-b-tooltip.hover.v-primary
              v-b-modal.modal-generate-invoice
              variant="primary"
              class="btn-icon ml-50"
              :title="t('Sinh hóa đơn')"
            >
              <feather-icon icon="LayersIcon" />
            </b-button>

          </template>
        </crud-buttons>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col v-if="isEnableLocationModule()">
            <select-location
              v-model="location"
              :label="null"
            />
          </b-col>
          <b-col>
            <select-apartment
              v-model="apartment"
              :location="location"
            />
          </b-col>
          <b-col>
            <select-room
              v-model="room"
              :apartment="apartment"
            />
          </b-col>
          <b-col
            v-if="resolveColWidthIfDisableBed(true) > 0"
          >
            <select-bed
              v-model="bed"
              :room="room"
            />
          </b-col>
          <b-col :cols="resolveColWidthIfDisableBed()">
            <select-contract
              v-model="contract"
              :ignore-liquid="false"
              :default-value="contract"
              :apartment="apartment"
              :room="room"
              :bed="bed"
            />
          </b-col>

        </b-row>
        <b-row>
          <b-col>
            <select-month
              v-model="month"
              :default-value="month"
            />
          </b-col>
          <b-col>
            <select-approve-status
              v-model="approveStatus"
              :default-value="approveStatus"
            />
          </b-col>

          <b-col>
            <select-payment-status
              v-model="paymentStatus"
              :default-value="paymentStatus"
            />
          </b-col>
          <b-col>
            <select-due-date
              v-model="dueDateStatus"
              :default-value="dueDateStatus"
            />
          </b-col>
          <b-col v-if="userData && ( userData.id === 2020 || userData.id === 3264 || (userData.manager && userData.manager.ownerId === 2020))">
            <select-invoice-mixed-type
              v-model="mixedType"
              :default-value="mixedType"
            />
          </b-col>
          <b-col>
            <select-invoice-type
              v-model="invoiceType"
              show-full-options
              :default-value="invoiceType"
            />
          </b-col>
          <b-col>
            <b-form-input
              v-model="searchTerm"
              :placeholder="t('Tìm kiếm')"
              debounce="1000"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped bordered"
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-invoice-preview
                    class="font-weight-bold"
                    @click="onEditItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>

                </span>
                <!-- Column: Customer -->
                <span v-else-if="props.column.field === 'name'">
                  <span>{{ props.row.name }}</span>
                  <small
                    v-if="props.row.note"
                    class="text-muted"
                  ><br>{{ props.row.note }}</small>

                </span>
                <!-- Column: Customer -->
                <span v-else-if="props.column.field === 'customer'">
                  <div>
                    <span v-if="props.row.contract">{{ props.row.contract.name }}</span>
                    <span v-else-if="props.row.reservation && props.row.reservation.tenant">{{ props.row.reservation.tenant.name }}</span>
                    <span
                      v-if="props.row.apartment"
                      class="text-muted"
                    ><br>{{ t('Tòa nhà') }}: {{ props.row.apartment.name }}</span>
                    <span
                      v-if="props.row.room"
                      class="text-muted"
                    ><br>{{ t('Phòng') }}: {{ props.row.room.name }}</span>
                    <span
                      v-if="props.row.bed"
                      class="text-muted"
                    ><br>{{ t('Giường') }}: {{ props.row.bed.name }}</span>
                  </div>
                </span>

                <!-- Column: Total -->
                <span v-else-if="props.column.field === 'total'">
                  {{ Number(props.row.total).toLocaleString() }}
                </span>
                <!-- Column: Total -->
                <span v-else-if="props.column.field === 'totalLease'">
                  {{ Number(props.row.totalLease).toLocaleString() }}
                </span>
                <!-- Column: Total -->
                <span v-else-if="props.column.field === 'totalOtherFee'">
                  {{ Number(props.row.totalOtherFee).toLocaleString() }}
                </span>

                <!-- Column: Paid -->
                <span v-else-if="props.column.field === 'paid'">
                  <b-link
                    v-b-modal.modal-invoice-payment-history
                    class="font-weight-bold"
                    @click="onEditItem(props.row)"
                  >{{ Number(props.row.paid).toLocaleString() }}</b-link>
                </span>

                <!-- Column: Remain -->
                <span v-else-if="props.column.field === 'remain'">
                  {{ Number(props.row.remain).toLocaleString() }}
                </span>

                <!-- Column: Remain -->
                <span v-else-if="props.column.field === 'totalDebt'">
                  {{ Number(props.row.totalDebt).toLocaleString() }}
                </span>

                <!-- Column: Due date -->
                <span
                  v-else-if="props.column.field === 'dueDate'"
                  class="flex"
                >
                  <div>
                    <span>
                      {{ parseDateToString(props.row.dueDate) }}
                    </span>

                    <b-badge
                      v-if="props.row.remain !== 0"
                      pill
                      :variant="getRemainDaysVariant(props.row.remainDays).variant"
                      class="font-small-1"
                    >
                      {{ getRemainDaysVariant(props.row.remainDays).text }}
                    </b-badge>
                  </div>

                </span>

                <!-- Column: Paid -->
                <span v-else-if="props.column.field === 'approve'">

                  <b-badge
                    pill
                    class="size-18"
                    :variant="`light-${resolveInvoiceApproveStatusVariantAndIcon(props.row.approve).variant}`"
                  >{{ resolveInvoiceApproveStatusVariantAndIcon(props.row.approve).title }}</b-badge>

                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    :name="t('Hóa đơn')"
                    :disable-delete="props.row.approve"
                    :disable-edit="false"
                    :hide-print="false"
                    :hide-payment="props.row.remain === 0 || !props.row.approve || !$can('update', 'invoice')"
                    :payment-modal="'modal-invoice-payment'"
                    modal="modal-invoice"
                    :hide-delete="!$can('delete', 'invoice') || props.row.type === '8'"
                    :hide-edit="!$can('update', 'invoice') || props.row.type === '8'"
                    @on-delete="onDelete(props.row)"
                    @on-edit="onEditItem(props.row)"
                    @on-payment="onSelectPayment([props.row])"
                  >
                    <template
                      slot="other-buttons"
                    >
                      <b-button

                        v-if="!props.row.approve && $can('approve', 'invoice') && props.row.type !== '8'"
                        v-b-tooltip.hover.v-success
                        variant="success"
                        class="btn-icon mr-50"
                        :title="t('Duyệt')"
                        size="sm"
                        @click="onApproveClick([props.row])"
                      >
                        <feather-icon icon="CheckSquareIcon" />
                      </b-button>
                      <b-button
                        v-if="props.row.approve && $can('approve', 'invoice') && props.row.type !== '8'"
                        v-b-tooltip.hover.v-secondary
                        :disabled="props.row.approve === false || parseInt(props.row.paid - props.row.prepaid, 10) !== 0"
                        variant="secondary"
                        class="btn-icon mr-50"
                        :title="t('Bỏ duyệt')"
                        size="sm"
                        @click="onDeclineClick([props.row])"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                      <b-button
                        v-if="$can('pay', 'invoice')"
                        v-b-tooltip.hover.v-warning
                        v-b-modal.modal-invoice-payment
                        variant="warning"
                        class="btn-icon mr-50"
                        :title="t('Thu tiền')"
                        size="sm"
                        :disabled="setPaymentHidden([props.row])"
                        @click="onSelectPayment([props.row])"
                      >
                        <feather-icon icon="CreditCardIcon" />
                      </b-button>
                    </template>
                  </table-actions>
                </span>
                <span v-else-if="props.column.field === 'creator'">
                  {{ props.row.creator && props.row.creator.user ? props.row.creator.user.name : props.row.owner.name }}
                </span>

                <!-- Column: Common -->
                <span
                  v-else
                >
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ t('Hiển thị tối đa') }} </span>
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '20', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) => props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      {{ t('trên tổng số') }} {{ props.total }} {{ t('kết quả') }}
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  {{ t('Không có bản ghi nào!') }}
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <invoice-preview-modal
      :item="item"
      @approve-invoice="onApproveClick([item])"
      @decline-invoice="onDeclineClick([item])"
      @pay-invoice="openPayModal([item])"
    />
    <invoice-payment-modal
      ref="payment-modal"
      :item="paymentItem"
      @refetch-data="fetchData"
    />
    <payment-history-modal
      ref="payment-history-modal"
      :item="item"
      @refetch-data="fetchData"
    />
    <invoice-modal
      :item="item"
      @refetch-data="fetchData"
    />
    <invoice-send-modal
      :item="item"
      @refetch-data="fetchData"
    />
    <generate-invoice-modal />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  BBadge,
  BButton,
  VBModal,
  VBTooltip,
  BFormInput,
} from 'bootstrap-vue';
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectRoom from '@/views/components/SelectRoom.vue';
import SelectBed from '@/views/components/SelectBed.vue';
import { VueGoodTable } from 'vue-good-table';
import TableActions from '@/views/components/TableActions.vue';
import CrudButtons from '@/views/components/CrudButtons.vue';
import SelectMonth from '@/views/components/SelectMonth.vue';
// eslint-disable-next-line import/no-cycle
import SelectApproveStatus from '@/views/components/SelectApproveStatus.vue';
import SelectContract from '@/views/components/SelectContract.vue';
import SelectPaymentStatus from '@/views/components/SelectPaymentStatus.vue';
import SelectDueDate from '@/views/components/SelectDueDate.vue';
import CardStatistic from '@/views/components/CardStatistic.vue';
import { getUserData, parseDateToString } from '@/auth/utils';
import { nextTick } from '@vue/composition-api';
import SelectInvoiceMixedType from '@/views/components/SelectInvoiceMixedType.vue';
import SelectInvoiceType from '@/views/components/SelectInvoiceType.vue';
import SelectLocation from '@/views/components/SelectLocation.vue';
import useInvoiceList from './useInvoiceList';
import InvoicePreviewModal from '../preview/InvoicePreviewModal.vue';
import InvoiceModal from '../modal/InvoiceModal.vue';
import InvoicePaymentModal from '../payment/InvoicePaymentModal.vue';
import GenerateInvoiceModal from '../generate/GenerateInvoiceModal.vue';
import InvoiceSendModal from '../send/InvoiceSendModal.vue';
import PaymentHistoryModal from '../payment-history/PaymentHistoryModal.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    BBadge,
    BButton,
    BFormInput,
    SelectApartment,
    SelectRoom,
    SelectBed,
    VueGoodTable,
    TableActions,
    CrudButtons,
    InvoicePreviewModal,
    InvoiceModal,
    InvoicePaymentModal,
    SelectMonth,
    GenerateInvoiceModal,
    SelectApproveStatus,
    SelectContract,
    SelectPaymentStatus,
    SelectDueDate,
    CardStatistic,
    InvoiceSendModal,
    PaymentHistoryModal,
    SelectInvoiceMixedType,
    SelectInvoiceType,
    SelectLocation,
  },
  directives: {
    'b-modal': VBModal,
    "b-tooltip": VBTooltip,
  },
  setup() {
    const {
      analytics,
      item,
      paymentItem,
      columns,
      rows,
      location,
      apartment,
      room,
      bed,
      contract,
      month,
      status,
      paymentStatus,
      type,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      approveStatus,
      dueDateStatus,
      mixedType,
      invoiceType,
      exportZipPdf,
      importData,
      exportData,
      downloadImportTemplate,
      approveInvoice,
      fetchData,
      deleteInvoices,
      onEditItem,
      resetItem,
      onSelectPayment,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      resolveInvoiceApproveStatusVariantAndIcon,
      getRemainDaysVariant,
      setPaymentHidden,
      setApproveHidden,
      setDeclineHidden,
      setDeleteEnable,
      onCreateMultiple,
      resolveColWidthIfDisableBed,
      exportZipImages,
      t,
      isEnableLocationModule,
      isTingTong,
    } = useInvoiceList();

    const userData = getUserData();

    return {
      isEnableLocationModule,
      userData,
      analytics,
      item,
      paymentItem,
      columns,
      rows,
      location,
      apartment,
      room,
      bed,
      contract,
      month,
      status,
      paymentStatus,
      type,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      approveStatus,
      dueDateStatus,
      mixedType,
      invoiceType,
      exportZipPdf,
      importData,
      exportData,
      downloadImportTemplate,
      approveInvoice,
      fetchData,
      deleteInvoices,
      onEditItem,
      onSelectPayment,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      resolveInvoiceApproveStatusVariantAndIcon,
      getRemainDaysVariant,
      setPaymentHidden,
      setApproveHidden,
      setDeclineHidden,
      setDeleteEnable,
      parseDateToString,
      onCreateMultiple,
      resolveColWidthIfDisableBed,
      exportZipImages,
      t,
      isTingTong,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    openPayModal(items) {
      this.onSelectPayment(items);
      nextTick(() => {
        this.$refs['payment-modal'].$refs.refModal.show();
      });
    },
    onDelete(invoice) {
      const deleteObjects = invoice && invoice.id > 0 ? [invoice] : this.selectedRows;
      this.deleteInvoices(deleteObjects);
    },
    onApproveClick(invoices) {
      this.$bvModal
        .msgBoxConfirm(
          `Bạn đang thực hiện thao tác DUYỆT. Bạn có chắc chắn muốn xác nhận duyệt này không?`,
          {
            title: `Duyệt`,
            okTitle: 'Duyệt',
            cancelTitle: 'Huỷ',
            okVariant: 'primary',
            cancelVariant: 'outline-secondary',
          },
        )
        .then(value => {
          if (value) {
            this.approveInvoice(invoices, true);
          }
        });
    },
    onDeclineClick(invoices) {
      this.$bvModal
        .msgBoxConfirm(
          `Bạn đang thực hiện thao tác BỎ DUYỆT. Bạn có chắc chắn muốn hủy duyệt không?`,
          {
            title: `Bỏ duyệt`,
            okTitle: 'Bỏ duyệt',
            cancelTitle: 'Huỷ',
            okVariant: 'warning',
            cancelVariant: 'outline-secondary',
          },
        )
        .then(value => {
          if (value) {
            this.approveInvoice(invoices, false);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.invoice-list-container {
  .vgt-table {
      thead {
        tr {
          th {
            min-width: 120px !important;

            &:first-child {
              min-width: 50px !important;
            }

            &:nth-child(2) {
               min-width: 80px !important;
            }
            &:nth-child(4) {
               min-width: 120px !important;
            }
            &:nth-child(5) {
               min-width: 200px !important;
            }
            &:nth-child(6) {
               min-width: 120px !important;
            }
            &:nth-child(7) {
               min-width: 120px !important;
            }
            &:nth-child(8) {
               min-width: 120px !important;
            }
            &:nth-child(9) {
               min-width: 120px !important;
            }
            &:nth-child(10) {
               min-width: 90px !important;
            }
          }
        }
      }
    }
}
</style>
