import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMeterLogs(ctx, params) {
      return useJwt.getMeterLogs(params).then(response => response);
    },
    fetchAnalytics(ctx, params) {
      return useJwt.getMeterLogAnalytics(params).then(response => response);
    },
    exportMeterLogs(ctx, params) {
      return useJwt.exportMeterLogs(params).then(response => response);
    },
    createMeterLog(ctx, data) {
      return useJwt.createMeterLog(data).then(response => response);
    },
    approveMeterLogs(ctx, data) {
      return useJwt.approveMeterLogs(data).then(response => response);
    },
    updateMeterLog(ctx, data) {
      return useJwt.updateMeterLog(data).then(response => response);
    },
    getMeterLogDetail(ctx, id) {
      return useJwt.getMeterLogDetail(id).then(response => response);
    },
    deleteMeterLogs(ctx, data) {
      return useJwt.deleteMeterLogs(data).then(response => response);
    },
    generateMeterLogTemplate(ctx, params) {
      return useJwt.generateImportMeterLogTemplate(params).then(response => response);
    },
    importMeterLogs(ctx, data) {
      return useJwt.importMeterLogs(data).then(response => response);
    },
  },
};
