<template>
  <b-form-group
    :label="label"
  >
    <template
      v-if="label"
      v-slot:label
    >
      {{ label }}
      <span
        v-if="required"
        class="text-danger"
      >
        (*)
      </span>
    </template>
    <v-select
      id="approve"
      v-model="approve"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="title"
      :options="options"
      :placeholder="t('Trạng thái duyệt')"
      @input="valueChanged"
    >
      <!-- eslint-disable-next-line vue/no-unused-vars  -->
      <template #no-options="{ search, searching, loading }">
        {{ t('Không có dữ liệu') }}
      </template></v-select>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      approve: this.defaultValue,
      options: [
        { title: 'Đã duyệt', id: 1, value: true },
        { title: 'Chưa duyệt', id: 0, value: false },
      ],
    };
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
  methods: {
    valueChanged() {
      this.$emit('input', this.approve);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
</style>
